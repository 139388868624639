import { axios } from '@/utils/request'

// 商户信息
export function GetUser ( data ) {
  return axios({
    headers : {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    },
    url: 'account/getAccountInfoByUserId',
    method: 'post',
    data
  })
}
// 分页商户信息
export function GetUserList ( data ) {
    return axios({
      url: '/account/getAccountPage',
      method: 'post',
      data
    })
  }

  // 分页商户流水
export function GetUserListflow ( data ) {
    return axios({
      url: 'account/getAccountFlowPage',
      method: 'post',
      data
    })
  }
