<template>
  <div>
    <div style="background-color: #f0f2f5; padding: 20px; margin-bottom: 40px">
      <a-row :gutter="16">
        <a-col :span="8">
          <a-card title="代付账户可用余额" :bordered="false">
            <p style="font-size: 16px; font-weight: 500">{{ formatPrice(user.balance || 0) }}</p>
          </a-card>
        </a-col>
        <a-col :span="8">
          <a-card title="代付账户在途余额" :bordered="false">
            <p style="font-size: 16px; font-weight: 500">{{ formatPrice(user.freezeAmount || 0) }}</p>
          </a-card>
        </a-col>
        <a-col :span="8">
          <a-card title="代收账户余额" :bordered="false">
            <p style="font-size: 16px; font-weight: 500">{{ formatPrice(user.transitAmount || 0) }}</p>
          </a-card>
        </a-col>
      </a-row>
    </div>
    <div>
      <!-- <div class="head">
        <div class="balance">代付账户可用余额: {{ formatPrice(user.balance)  }}</div>
        <div class="freezeAmount">代付账户在途余额: {{ formatPrice(user.freezeAmount) }}</div>
        <div class="transitAmount">代收账户余额: {{ formatPrice(user.transitAmount) }}</div>
      </div> -->
      <div>
        <a-form layout="inline" @keyup.enter.native="inquire" style="margin-bottom: 30px">
          <a-row>
            <a-col :span="8">
              <a-form-item label="完成时间" :labelCol="{ span: 9 }" :wrapperCol="{ span: 15 }">
                <!-- <a-date-picker v-model="queryParam.beginTime" style="width: 200px" /> -->
                <a-range-picker v-model="userdata.createTime" style="width: 240px" @change="changeSelectDate">
                </a-range-picker>
              </a-form-item>
            </a-col>
            <a-col :span="4">
              <a-form-item label="账户类型" :labelCol="{ span: 9 }" :wrapperCol="{ span: 15 }">
                <a-select default-value="代付" style="width: 120px" @change="handleChange">
                  <a-select-option value="1"> 代付 </a-select-option>
                  <a-select-option value="2"> 代收 </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item label="订单号" :labelCol="{ span: 9 }" :wrapperCol="{ span: 15 }">
                <a-input placeholder="订单号" v-model="userdata.orderNo" style="width: 220px"></a-input>
              </a-form-item>
            </a-col>
            <a-col :span="5" :offset="1">
              <a-button
                type="primary"
                @click="
                  () => {
                    userdata.pageNum = 1
                    listinquire()
                  }
                "
                style="margin-right: 20px"
              >
                查询
              </a-button>
              <a-button type="primary" @click="reset"> 重置 </a-button>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <div>
        <a-table
          :loading="loading"
          :pagination="pagination"
          rowKey="id"
          :columns="columns"
          :data-source="data"
          bordered
        >
          <template slot="beginBalance" slot-scope="text">
            <span>{{ formatPrice(text) }}</span>
          </template>
          <template slot="endBalance" slot-scope="text">
            <span>{{ formatPrice(text) }}</span>
          </template>
          <template slot="amount" slot-scope="text">
            <span>{{ formatPrice(text) }}</span>
          </template>
          <span slot="accountType" slot-scope="text, record">
            {{ getAccountTypeValue(record.accountType) }}
          </span>
          <span slot="amountType" slot-scope="text, record">
            {{ getCashFromValue(record.amountType) }}
          </span>
        </a-table>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { GetUser, GetUserListflow } from '../../../api/user.js'
import { findDictItemByCode } from '@/api/pay.js'
// 商户信息
const columns = [
  {
    title: '完成时间',
    dataIndex: 'createTime',
    align: 'center',
  },
  {
    title: '平台订单号  ',
    dataIndex: 'orderNo',
    align: 'center',
  },
  {
    title: '账户类型',
    dataIndex: 'accountType',
    align: 'center',
    scopedSlots: { customRender: 'accountType' },
  },
  {
    title: '金额类型',
    dataIndex: 'amountType',
    align: 'center',
    scopedSlots: { customRender: 'amountType' },
  },
  {
    title: '交易金额',
    dataIndex: 'amount',
    align: 'right',
    scopedSlots: { customRender: 'amount' },
  },
  {
    title: '期初金额',
    dataIndex: 'beginBalance',
    align: 'right',
    scopedSlots: { customRender: 'beginBalance' },
  },
  {
    title: '期末金额',
    dataIndex: 'endBalance',
    align: 'right',
    scopedSlots: { customRender: 'endBalance' },
  },
]
let accountType = []
let cashFromArr = []

export default {
  data() {
    return {
      moment,
      // 加载
      loading: false,
      // table表格
      columns,
      data: [],
      // 日期
      time: {
        startDate: null,
        endDate: null,
      },
      endOpen: false,
      // 账户信息
      userId: null,
      user: {
        balance: null, // 账户余额
        freezeAmount: null, // 冻结金额
        transitAmount: null, // 在途金额
      },
      // 账户流水
      userdata: {
        userNo: this.$store.state.user.userNo,
        userId: this.$store.state.user.userId,
        startDate: '开始日期',
        endDate: '结束日期',
        createTime: '',
        accountType: '1',
        pageNum: '1',
        pageSize: '10',
      },
      // 分页
      pagination: {
        pageSize: 0, // 每页显示的条数
        showSizeChanger: true, // 是否可以改变每页显示的条数
        pageSizeOptions: ['5', '10', '15', '20'], // 可选的每页显示条数
        showQuickJumper: true, // 是否可以快速跳转到指定页
        showTotal: 0, // 显示总条数和当前数据范围
        current: 0, // 当前页数
        total: 0, // 总条数
        onChange: this.handlePageChange, // 页码改变时的回调函数
      },
    }
  },
  created() {
    this.userdata.createTime = [moment().subtract(7, 'days'), moment().add(0, 'days')]
    this.usersms(this.userdata.userId)

    findDictItemByCode('accountType,amountType').then((res) => {
      if (res.code == '2000') {
        accountType = res.data.accounttype
        cashFromArr = res.data.amounttype

        setTimeout(() => {
          this.listinquire()
        }, 500)
      }
    })
  },
  watch: {
    startValue(val) {
      console.log('startValue', val)
    },
    endValue(val) {
      console.log('endValue', val)
    },
  },
  methods: {
    changeSelectDate(date) {
      if (date.length === 0) {
        this.userdata.createTime = null
      }
    },
    getAccountTypeValue(val) {
      console.log(accountType)
      const type = accountType.find((item) => item.itemValue == val)
      return type ? type.itemText : ''
    },
    getCashFromValue(val) {
      const type = cashFromArr.find((item) => item.itemValue == val)

      return type ? type.itemText : '-'
    },
    formatPrice(price) {
      return String(price).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    // 查询
    listinquire() {
      const params = { ...this.userdata }
      const { createTime } = params
      if (createTime) {
        params.startDate = `${moment(createTime[0]).format('YYYY-MM-DD')} 00:00:00`
        params.endDate = `${moment(createTime[1]).format('YYYY-MM-DD')} 23:59:59`
        delete params.createTime
      } else {
        params.startDate = ''
        params.endDate = ''
      }

      GetUserListflow(params).then((res) => {
        this.loading = true
        console.log(res)
        this.data = res.data.list
        this.pagination = {
          pageSize: res.data.page.pageSize,
          showSizeChanger: true,
          pageSizeOptions: ['5', '10', '15', '20'],
          showQuickJumper: true,
          showTotal: (total) => `共 ${total} 条`,
          current: res.data.page.pageNum,
          total: res.data.page.total,
          onChange: this.handlePageChange,
        }
        this.loading = false
      })
    },
    // 重置
    reset() {
      this.pagination = false
      this.userdata.orderNo = ''
      this.userdata.startDate = ''
      this.userdata.endDate = ''
      this.userdata.createTime = [moment().subtract(7, 'days'), moment().add(0, 'days')]
      this.data = []
    },
    // 可用/冻结/在途余额
    usersms(val) {
      this.userId = new FormData()
      this.userId.append('userId', val)
      GetUser(this.userId).then((res) => {
        this.user = {
          balance: res.data.availableAmount, // 账户余额
          freezeAmount: res.data.freezeAmount, // 冻结金额
          transitAmount: res.data.transitAmount, // 在途金额
        }
      })
    },
    // 下拉框
    handleChange(value) {
      console.log(value, '下拉框')
      this.userdata.accountType = value
    },
    // 分页选择
    handlePageChange(page, pageSize) {
      console.log(page, pageSize)
      this.loading = true
      this.pagination = {
        current: page,
        pageSize,
      }
      this.userdata.pageNum = page
      this.listinquire()
      this.loading = false
    },
    // 日期选择
    disabledStartDate(startValue) {
      const endValue = this.userdata.endDate
      if (!startValue || !endValue) {
        return false
      }
      return startValue.valueOf() > endValue.valueOf()
    },
    disabledEndDate(endValue) {
      const startValue = this.userdata.startDate
      if (!endValue || !startValue) {
        return false
      }
      return startValue.valueOf() >= endValue.valueOf()
    },
    handleStartOpenChange(open) {
      if (!open) {
        this.endOpen = true
      }
    },
    handleEndOpenChange(open) {
      this.endOpen = open
    },
  },
}
</script>

<style lang="less">
/*  background: #f0f2f5 url(~@/assets/background.svg) no-repeat 50%; */
.amount {
  text-align: center;
  margin: 20px;
  background-color: #fff;
  padding: 30px;
  box-shadow: 0px 1px 5px #ccc;
}

.title {
  font-size: 20px;
}

th.column-money,
td.column-money {
  text-align: right !important;
}
.head {
  position: relative;
  width: 578px;
  height: 318px;
  background-image: url('../../../assets/bubble.png');
  background-size: cover;
  margin: 0 auto;
  transform: scale(0.8);
  color: #1783fc;
  font-size: 18px;
  font-weight: bold;

  .balance {
    position: relative;
    top: 25px;
    right: 20px;
    text-align: right;
  }

  .freezeAmount {
    position: relative;
    top: 70px;
    right: 40px;
    text-align: right;
  }

  .transitAmount {
    position: relative;
    top: 125px;
    right: 50px;
    text-align: right;
  }
}
</style>
